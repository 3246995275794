<template>
  <div>
    <h2 class="text-center font-weight-bolder text-dark font-size-h4 font-size-h1-lg mb-12">
      Silahkan Login
    </h2>
    <form>
      <b-form-group label="No. SKT Aktif" label-for="no_skt-input">
        <b-form-input id="no_skt-input" type="text" placeholder="Silahkan Masukan Nomor SKT Aktif"
          v-model="$v.form.email.$model" :state="validateState('email')"></b-form-input>
      </b-form-group>
      <b-form-group label="Kata Sandi" label-for="password-input">
        <b-form-input id="name-input" type="password" v-on:keyup.enter="handleSubmit()"
          placeholder="Silahkan masukan kata sandi" v-model="$v.form.password.$model"
          :state="validateState('password')"></b-form-input>
        <transition name="fade-in-up">
          <router-view />
        </transition>
        <router-link to="/lupapassword" class="d-flex justify-content-end pt-4">
          <a class="text-muted text-sm-end pt-5 ">Lupa Kata sandi ? <a href="#" class="anchor">Klik Disini</a></a>
        </router-link>
      </b-form-group>

      <div class="text-centerLogin">

        <b-button class="button-back tombol-DiLogin" style="margin-right;: 2.5% !important" variant="outline-primary" size="lg"
          @click="$router.push('/')" type="button">
          Kembali
        </b-button>
        <b-button class="ml-8 button-submit tombol-DiLogin" style="margin-left: 2.5% !important" size="lg" type="button"
          @click="handleSubmit()">
          <span v-if="!loading">Login</span>
          <b-spinner medium v-else></b-spinner>
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";


export default {
  mixins: [validationMixin],
  name: "LoginFloat",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      },
      loading: false
    };
  },
  validations: {
    form: {
      email: {
        required
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  mounted(){
    if(localStorage.getItem('token')) {
      this.$store
        .dispatch("getProfile").then(()=>{
          this.$router.push('/dashboard')
        }).catch(()=>{
          localStorage.removeItem('token')
          location.reload()
        })
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async handleSubmit() {
      this.loading = true
      let context = this
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.loading = false
        return;
      }

      const username = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      const reCaptcha = await this.recaptcha();
      this.$store
        .dispatch("login", { username, password, reCaptcha })
        .then((res) => {
          if(!res.data.isLengkap) this.$router.push('/formdataormas')
          else this.$router.push({ name: "dashboard" })
          this.loading = false
        })
        .catch(response => {
           context.loading = false
           if (response.message === 'Request failed with status code 401') {
             Swal.fire({
              title: "Gagal Login",
                icon: "warning",
                text: "Silahkan periksa no skt dan password anda kembali",
              showCancelButton: false,
              confirmButtonColor: "#063A69",
              confirmButtonText: "Tutup",
            }).then(() => {
            })
           } else {
             Swal.fire({
              title: "Terjadi Kesalahan",
                icon: "warning",
                text: "Silahkan coba lagi beberapa saat",
                confirmButtonColor: "#063A69",
                confirmButtonText: "Tutup",
                cancelButtonText: false
            }).then(() => {
            })
           }
        })
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      return token
    },

    // clear existing errors
    // this.$store.dispatch(LOGOUT);

    // set spinner to submit button
    /*const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");*/

    // dummy delay
    // setTimeout(() => {
    //     // send login request
    //     this.$store
    //       .dispatch(LOGIN, { email, password })
    //       // go to which page after successfully login
    //       .then(() => this.$router.push({ name: "dashboard" }));

    //     submitButton.classList.remove(
    //       "spinner",
    //       "spinner-light",
    //       "spinner-right"
    //     );
    //   }, 2000);
    // }
  },
  beforeDestroy() {
    // Hide badge recaptcha
    const recaptcha = this.$recaptchaInstance
    recaptcha.hideBadge()
  },
  computed: {
    // loggedIn() {
      // return this.$store.state.auth.status.loggedIn;
    // }
  },
  async created() {
    // if (this.loggedIn) {
    //   this.$router.push("/profile");
    // }
  }
};
</script>

<style scoped>


.tombol-DiLogin {
  width: 29.917%;
  height: auto !important;
  margin-left: 0px !important;
  text-align: center;
  padding: 3% !important;
}
.text-centerLogin {
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}


.button-submit {
  margin-top: 20px;
  background: #063a69;
  color: #ffffff;
  box-shadow: 0 4px 15px rgba(5, 58, 105, 0.25);
  border-radius: 10px;
  padding: 16px 60px;
}

.anchor {
  color: #063a69;
  text-decoration: underline;
}
@media screen and (min-device-width: 240px) and (min-width: 240px) and (max-device-width: 640px) and (max-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
  .button-back {
    display: none;
  }
    .ml-8,.mx-8 {
      margin-left: 0px !important;
    }
}
</style>
